@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.activity-area {
  padding: 30px 0;
}

.chk-list {
  @extend %listreset;

  li {
    position: relative;
    padding: 0 0 10px 35px;
    line-height: 1.42857em;
  }

  .ico {
    position: absolute;
    top: 0;
    left: 0;

    &:before {
      color: $red;
    }

    @include media('>=desktop') {
      top: 2px;
    }

    &.icon-ico-profile {
      font-size: 16px;
      left: 1px;
      top: 1px;
      @include media('>=desktop') {
        top: 3px;
      }
    }
  }

  a {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 6px;
    font-size: 12px;
  }

  .date {
    color: $gray;
    margin-left: 6px;
  }
}

.link-more {
  display: inline-flex;
  vertical-align: middle;
  text-transform: uppercase;
  color: $red;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  margin: 20px 0 0 35px;
  min-height: 0;
  min-width: 0;
  background: transparent !important;
  box-shadow: none !important;
  padding: 0;
  border-radius: 0;

  &:hover {
    color: $darken-red;
  }
}
