@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.content-types {
  @extend %listreset;
  padding: 40px 0;

  @include media('>=tablet') {
    column-count: 2;
    column-gap: 15px;
  }

  li {
    position: relative;
    padding: 5px 0 5px 35px;
  }

  .ico {
    position: absolute;
    top: 3px;
    left: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media('>=desktop') {
      top: 5px;
    }
  }
}