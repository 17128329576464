@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?ufovms');
  src:  url('../../fonts/icomoon.eot?ufovms#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?ufovms') format('truetype'),
  url('../../fonts/icomoon.woff?ufovms') format('woff'),
  url('../../fonts/icomoon.svg?ufovms#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-complete-read:before {
  content: "\e904";
  color: #db2c28;
}
.icon-about-results:before {
  content: "\e921";
  color: #dc2b2a;
}
.icon-ico-warning-2 .path1:before {
  content: "\e922";
  color: rgb(254, 254, 254);
}
.icon-ico-warning-2 .path2:before {
  content: "\e923";
  margin-left: -1.142578125em;
  color: rgb(219, 44, 40);
}
.icon-ico-warning-2 .path3:before {
  content: "\e924";
  margin-left: -1.142578125em;
  color: rgb(219, 44, 40);
}
.icon-Icon_Video:before {
  content: "\e905";
  color: #b5b5b5;
}
.icon-Icon_Resources:before {
  content: "\e90b";
  color: #b5b5b5;
}
.icon-Icon_Report .path1:before {
  content: "\e90f";
  color: rgb(181, 181, 181);
}
.icon-Icon_Report .path2:before {
  content: "\e910";
  margin-left: -0.841796875em;
  color: rgb(151, 151, 151);
}
.icon-Icon_Report .path3:before {
  content: "\e911";
  margin-left: -0.841796875em;
  color: rgb(151, 151, 151);
}
.icon-Icon_Friendventory:before {
  content: "\e912";
  color: #b5b5b5;
}
.icon-Icon_DSSI .path1:before {
  content: "\e913";
  color: rgb(181, 181, 181);
}
.icon-Icon_DSSI .path2:before {
  content: "\e914";
  margin-left: -1.1767578125em;
  color: rgb(255, 255, 255);
}
.icon-Icon_DSSI .path3:before {
  content: "\e915";
  margin-left: -1.1767578125em;
  color: rgb(255, 255, 255);
}
.icon-Icon_DSSI .path4:before {
  content: "\e916";
  margin-left: -1.1767578125em;
  color: rgb(181, 181, 181);
}
.icon-Icon_COVID .path1:before {
  content: "\e917";
  color: rgb(181, 181, 181);
}
.icon-Icon_COVID .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(151, 151, 151);
}
.icon-Icon_COVID .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(151, 151, 151);
}
.icon-Icon_COVID .path4:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(151, 151, 151);
}
.icon-Icon_COVID .path5:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(151, 151, 151);
}
.icon-Icon_COVID .path6:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(151, 151, 151);
}
.icon-Icon_COVID .path7:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(151, 151, 151);
}
.icon-Icon_COVID .path8:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(151, 151, 151);
}
.icon-Icon_COVID .path9:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(151, 151, 151);
}
.icon-Icon_ComeBack:before {
  content: "\e920";
  color: #b5b5b5;
}
.icon-Icon_Chatbot:before {
  content: "\e902";
  color: #b5b5b5;
}
.icon-ico-arrow-up:before {
  content: "\e90e";
  color: #fff;
}
.icon-ico-arrow-down:before {
  content: "\e900";
  color: #fff;
}
.icon-ico-check:before {
  content: "\e901";
  color: #dc2b2a;
}
.icon-ico-close:before {
  content: "\e903";
  color: #fff;
}
.icon-ico-pencil:before {
  content: "\e906";
  color: #fff;
}
.icon-ico-popup-close:before {
  content: "\e907";
  color: #fff;
}
.icon-ico-profile:before {
  content: "\e908";
  color: #fff;
}
.icon-ico-question .path1:before {
  content: "\e909";
  color: rgb(220, 43, 42);
}
.icon-ico-question .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ico-warning .path1:before {
  content: "\e90c";
  color: rgb(220, 43, 42);
}
.icon-ico-warning .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
