@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';


.call-to-action {
  padding: 40px 0 30px;

  .h5 {
    margin: 0 0 5px;
  }

  .title {
    font-family: $base-font-serif;
    margin: 0 0 21px;

    @include media('>=desktop') {
      font-size: 48px;
      line-height: (60/48);
    }
  }

  iframe {
    height: 209px;
    width: 100%;
    border: none;
    vertical-align: top;
  }

  .intro {
    display: flex;
    flex-direction: column;
    @include media('>=desktop') {
      flex-direction: row;
      margin: 0 0 10px;
    }
  }

  .video-holder {
    @include media('<desktop') {
      order: 1;
    }
    @include media('>=desktop') {
      width: 33.3%;

      &+.text-holder {
        width: 66.6%;
      }
    }

    video {
      width: 100%;
      vertical-align: top;
    }
  }

  .text-holder {
    color: $white;
    background: $red;
    position: relative;
    padding: 30px 60px 30px 30px;

    @include media('>=desktop') {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .btn-close {
    position: absolute;
    top: 14px;
    right: 17px;
    width: 26px;
    height: 26px;
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 0;
    line-height: 0;
    min-width: 0;
    min-height: 0;
    box-shadow: none;
    padding: 0;

    &:before,
    &:after {
      position: absolute;
      content: '';
      background: $white;
      height: 2px;
      width: 32px;
    }

    &:before {
      top: 9px;
      left: -2px;
      transform: rotate(45deg);
    }

    &:after {
      top: 9px;
      right: -4px;
      transform: rotate(-45deg);
    }

    &:hover {
      &:before,
      &:after {
        background: $gray;
      }
    }
  }
}

.action-block {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: $red;
  color: $white;
  padding: 15px 60px 30px 20px;
  margin: 0;

  @include media('>=tablet') {

    align-items: center;
    padding: 41px 62px 41px 40px;
  }

  .decor {
    display: block;
    font-size: 30px;
    flex-shrink: 0;
    margin: 0 25px 20px 0;

    @include media('>=tablet') {
      margin: 0 37px 0 0;
    }

    @include media('>=tablet') {
      top: 41px;
      left: 41px;
      font-size: 70px;
    }
  }

  .image-holder {
    max-width: 100px;
    flex-shrink: 0;
    margin: 0 25px 20px 0;

    @include media('>=tablet') {
      margin: 0 37px 0 0;
    }
  }

  .text-holder {
    padding: 0;

    @include media('>=tablet') {
      flex-grow: 1;
      padding-right: 30px;
    }

    @include media('>=desktop') {
      padding-right: 110px;
    }

    .h5 {
      margin: 0 0 9px;
    }
  }

  .btn-wrap {
    @include media('<desktop') {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-grow: 1;
      padding-left: 40px;
    }
  }

  .btn-white {
    margin: 20px 0 0;
    min-height: 52px;
    @include media('>=tablet') {
      min-width: 221px;
    }

    @include media('<tablet') {
      padding: 10px 20px;
    }
  }
}