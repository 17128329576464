@import '../../assets/scss/base/variables';
@import '../../assets/scss/vendors/include-media';

.user-nav {
  display: flex;
  align-items: center;
  padding: 0 0 0 4px;
  box-shadow: none !important;
  background: transparent !important;
  border: none;
  border-radius: 0;
  min-width: 0;
  min-height: 0;

  img {
    max-width: 22px;
    height: auto;
  }
}

.user-name {
  display: block;
  white-space: nowrap;
  color: $white;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media('<376px') {
    max-width: 125px;
    font-size: 14px;
  }

  @include media('<375px') {
    max-width: 120px;
  }
}
