@import '../../assets/scss/vendors/include-media';

.risks-dialog-body {
  img {
    width: 105px;
    max-width: 105px;
  }
}

.dialog-window {
  .risks-dialog-body {
    h2 {
      font-size: 36px;
      text-align: left;
    }

    h5 {
      font-weight: 700;
    }
  }

  .row {
    padding: 15px 0;

    @include media('>=tablet') {
      display: flex;
      padding: 20px 0;
    }
  }

  .visual {
    padding: 0 0 30px;

    @include media('>=tablet') {
      flex-shrink: 1;
      padding: 0 40px 0 0;
    }
  }

  .text {
    @include media('>=tablet') {
      text-align: left;
      padding: 10px 0 0;
    }
  }
}
