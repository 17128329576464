@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.footer {
  text-align: center;
  background: $black;
  border-top: 40px solid $macko;
  color: $white;
  padding: 25px 0;

  .container {
    @include media('>=desktop') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.footer-nav {
  padding: 30px 0;
  ul {
    @extend %listreset;

    @include media('>=tablet') {
      padding: 0 20px;
      display: flex;
      justify-content: center;
    }
  }

  li {
    font-weight: bold;
    font-size: 12px;
    padding: 5px 0;

    @include media('>=tablet') {
      padding: 10px 15px;
    }
  }

  a {
    color: $white !important;

    &:hover {
      opacity: .7;
    }
  }
}

.social-networks {
  @extend %listreset;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    padding: 0 15px;

    @include media('>=tablet') {
      padding: 0 0 0 40px;
    }
  }
}
