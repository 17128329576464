@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';
@import '../../assets/scss/vendors/include-media';

.email-form {
  border:   1px solid $mercury;
  padding:  39px;
  position: relative;

  .content {
    display: flex;
  }

  &.layout-horizontal {
    margin-bottom:    4rem;
    
    @include media('<=tablet') {
      .content {
        flex-direction: column;
      }
    }

    @include media('>tablet') {
      .heading {
        text-align:     center;
        margin-bottom:  2rem;
      }

      .content {
        flex-direction:   row;
        justify-content:  space-between;

        & > p {
          flex: 2;
          margin-right: 2.5rem;
        }

        & > .field {
          flex: 1;
          margin-right: 1rem;
        }

        & > .buttons {
          flex: 0;
        }
      }
    }
  }

  &.layout-vertical {
    .content {
      flex-direction: column;
    }
  }
}
