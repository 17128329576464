@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.guest-actions {
  @extend %listreset;

  li {
    position: relative;
    padding: 5px 0 5px 35px;
  }

  .ico {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
