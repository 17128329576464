// Typography

@font-face {
	font-family: 'Lato';
	src: local(Lato),
	local(Lato Light),
	local(Lato-Light),
	url('../../fonts/lato-light-webfont.woff') format('woff'),
	url('../../fonts/lato-light-webfont.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: local(Lato),
	local(Lato Regular),
	local(Lato-Regular),
	url('../../fonts/lato-regular-webfont.woff') format('woff'),
	url('../../fonts/lato-regular-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: local(Lato),
	local(Lato Bold),
	local(Lato-Bold),
	url('../../fonts/lato-bold-webfont.woff') format('woff'),
	url('../../fonts/lato-bold-webfont.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: local(Lato),
	local(Lato Black),
	local(Lato-Black),
	url('../../fonts/lato-black-webfont.woff') format('woff'),
	url('../../fonts/lato-black-webfont.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: local(Merriweather),
	local(Merriweather Regular),
	local(Merriweather-Regular),
	url('../../fonts/merriweather-regular-webfont.woff') format('woff'),
	url('../../fonts/merriweather-regular-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Merriweather';
	src: local(Merriweather),
	local(Merriweather Regular),
	local(Merriweather-Regular),
	url('../../fonts/merriweather-italic-webfont.woff') format('woff'),
	url('../../fonts/merriweather-italic-webfont.woff2') format('woff2');
	font-weight: 400;
	font-style: italic;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
	font-family: $headings-font-family;
	font-weight: normal;
	margin: 0 0 0.5em;
}

h1, .h1 {
	font-size: 36px;
	@include media('>=desktop') {
		font-size: 48px;
	}
}

h2, .h2 {
	font-weight: 300;
	font-family: $base-font-sans-serif;
	font-size: 24px;
	@include media('>=desktop') {
		font-size: 36px;
	}

}

h3, .h3 {
	font-size: 20px;
	@include media('>=desktop') {
		font-size: 24px;
	}
}

h4, .h4 {
	font-size: 16px;
	@include media('>=desktop') {
		font-size: 18px;
	}
}

h5, .h5 {
	font-family: $base-font-sans-serif;
	font-size: 20px;
	@include media('>=desktop') {
		font-size: 24px;
	}
}

h6, .h6 {
	font-style: italic;
	font-size: 24px;
}

.underlined {
	position: relative;
	padding-bottom: 21px;

	&:after {
		position: absolute;
		content: '';
		width: 88px;
		height: 4px;
		background: $red;
		bottom: 0;
		left: 0;
	}
}

p {
	margin: 0 0 1em;
	line-height: 1.42857em !important;
}

a {
	text-decoration: none;
	color: $base-link-color;
	&:hover,
	&:focus {
		text-decoration: none;
		color: $base-link-hover-color;
	}
}

small {
	color: $gray;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 10px;
	@include media('>=desktop') {
		font-size: 12px;
	}
}

a.link-arrow {
	display: inline-block;
	position: relative;
	font-size: 16px;
	line-height: 32px;
	padding: 2px 0 2px 30px;

	&:before {
		content: "\e90e";
		color: #B5B5B5;
		font-size: 13px;
		line-height: 1;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%) rotate(-90deg);
		font-family: 'icomoon' !important;
		speak: never;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.links {
	list-style: none;
	padding: 0;
	margin: 0 0 30px;

	li {
		padding: 0 0 10px;
	}
}
