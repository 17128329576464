@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.redirect-loader {
  background: $black;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
