@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.resources-area {
  background: $mercury;
  padding: 34px 18px 50px;
  margin-bottom: 17px;

  @include media('<desktop') {
    padding: 34px 15px 50px;
  }

  h2 {
    margin: 0 0 28px;
  }

  .card {
    outline: none;
    width: 322px !important;
    padding: 0 11px;

    .h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      margin: 0 0 15px;

      @include media('<desktop') {
        font-size: 29px;
      }
    }

    a,
    button {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;

      &:after {
        content: '>';
        margin-left: 5px;
      }
    }

    button {
      color: $base-link-color;
      background: transparent !important;
      box-shadow: none !important;
      font-size: 12px;
      text-transform: uppercase;
      padding: 0;
      min-width: 0;
      min-height: 0;

      &:hover {
        color: $base-link-hover-color;
      }
    }
  }

  .card-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $gray;
    background: $white;
    padding: 25px 18px 30px;

    @include media('>=desktop') {
      padding-bottom: 47px;
    }

    .underlined {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media('>=desktop') {
        padding-bottom: 29px;
        margin: 0 0 17px;
      }
    }
  }

  .desc {
    font-size: 15px;
    padding: 0 0 3px;
    line-height: 1.42857em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }

  a.action {
    line-height: 1.42857em;
  }

  .slick-arrow {
    width: 46px;
    height: 46px;
    min-width: 0;
    min-height: 0;
    border-radius: 50%;
    padding: 0;
    box-shadow: none;
    background: rgba(55, 56, 61, 0.8);
    z-index: 99;

    @include media('<desktop') {
      width: 30px;
      height: 30px;

    }

    &.slick-disabled {
      z-index: -1;
    }

    &:before {
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e90e';
      font-size: 14px;

      @include media('<desktop') {
        font-size: 10px;
      }
    }

    &.slick-next {
      right: 12px;
      transform: rotate(90deg) translateX(-23px);

      @include media('<desktop') {
        right: -18px;
      }
    }

    &.slick-prev {
      left: 24px;
      transform: rotate(-90deg) translateX(23px);

      @include media('<desktop') {
        left: -5px;
      }
    }
  }

  .slick-slider {
    margin: 0 -10px 0 -22px;
  }

  .slick-track {
    display: flex;

    &:after,
    &:before {
      display: none;
    }
  }

  .slick-slide {
    height: auto;

    > div,
    .card,
    .card-holder {
      height: 100%;
    }
  }
}
