@import '../../assets/scss/base/variables';

$flash-opacity:       0.8;
$transition-duration: 0.5;  // seconds
$display-duration:    5;    // seconds
$total-duration:      $transition-duration * 2 + $display-duration;

$transition-in-percentage:  $transition-duration / $total-duration * 100;
$display-percentage:        $display-duration / $total-duration * 100;
$transition-out-percentage: $transition-in-percentage + $display-percentage;

.flash {
  color:        white;
  font-weight:  bold;
  position:     absolute;
  top:          0;
  left:         100%;
  right:        0;
  overflow:     hidden;

  animation: show #{$total-duration}s;

  .flash-success,
  .flash-error {
    padding:  0.5rem 1rem;
    white-space: nowrap;
  }

  .flash-success {
    background-color: rgba($green, $flash-opacity);
  }

  .flash-error {
    background-color: rgba($darken-red, $flash-opacity);
  }
}

@keyframes show {
  0% {
    left: 100%;
  }

  #{$transition-in-percentage}% {
    left: 0;
  }

  #{$transition-out-percentage}% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}
