@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';

.dssi-result {
  overflow: hidden;
  padding-bottom: 26px;

  .hero-banner {
    margin-bottom: 32px;

    h1 {
      @include media('>=desktop') {
        line-height: 60px;
      }
    }
  }

  .result-area {
    padding: 0;

    .txt,
    .txt-lbl {
      font-weight: 700;
      font-size: 16px;
      @include media('<tablet') {
        font-size: 15px;
      }
    }

    .ico {
      font-size: 34px;
      vertical-align: bottom;
      margin-right: 10px;
    }

    .result-box {
      line-height: (22/16);
      p {
        font-weight: 400;
      }
    }

    .info {
      p {
        font-weight: 400;
      }
    }

    .row {
      @include media('<desktop') {
        display: block;

        .col {
          width: 100%;
        }
      }

      .txt-mob {
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        padding-bottom: 15px;

        @include media('>=desktop') {
          display: none;
        }
      }
    }

    .ttl-row {
      border-bottom: 1px solid #E6E6E6;
      margin: 35px 0 21px;
      span {
        display: none;
      }

      @include media('>=desktop') {
        display: flex;
        border-bottom: 1px solid $mercury;
        padding: 42px 0 7px;
        margin-top: 0;

        span {
          display: block;
          width: 50%;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 22px;
          font-weight: 700;
        }
      }
    }

    .bottom-row {
      margin: 0;
      padding: 24px 0;
    }
  }

  .links {
    li {
      padding: 0;
      line-height: 32px;
    }
  }
}

.link-row {
  padding: 9px 0;
  text-align: center;
}

.action-row {
  text-align: center;
  padding: 55px 0;
  margin-bottom: 50px;

  &.item-bg {
    position: relative;
    z-index: 1;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: -9999px;
      right: -9999px;
      background: $wild-sand;
      z-index: -1;
    }
  }
}

.result-container {
  display: flex;
  flex-wrap: wrap;
  padding: 25px 0;

  .underlined {
    width: 100%;
    margin-bottom: 41px;
  }

  .ico-box {
    width: 50px;
    float: left;
    margin: 5px 20px 10px 0;

    @include media('>=desktop') {
      width: 60px;
      margin: 10px 30px 10px 0;
    }

    img {
      display: block;
    }
  }

  .text-holder {
    line-height: (20/14);
    overflow: hidden;
    @include media('>=desktop') {
      line-height: (22/16);
      width: 69%;
      padding-right: 10%;
    }

    @include media('>=1200px') {
      padding-right: 18%;
    }

    p {
      margin: 0 0 1.4em;
    }
  }

  .visual {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include media('>=desktop') {
      width: auto;

      img {
        transform: translateY(-45px);
      }
    }

    img {
      display: block;
    }
  }
}

.result-box {
  display: flex;
  flex-wrap: wrap;
  padding: 45px 0;

  @include media('>=desktop') {
    padding: 106px 0;
  }

  .text-holder {
    @include media('>=desktop') {
      width: 50%;
      padding-right: 20px;
    }
  }

  .underlined {
    margin-bottom: 45px;
  }

  .visual {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;

    @include media('>=desktop') {
      width: 50%;
      padding-top: 0;

      img {
        transform: translateY(-15px);
      }
    }

    img {
      display: block;
    }
  }

  &.item-bg {
    position: relative;
    z-index: 1;
    flex-direction: column-reverse;

    @include media('>=desktop') {
      flex-direction: row-reverse;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: -9999px;
      right: -9999px;
      background: $wild-sand;
      z-index: -1;
    }

    .visual {
      @include media('<desktop') {
        padding: 0 20% 30px;
      }
    }
  }
}