@import '../../assets/scss/base/variables';
@import '../../assets/scss/vendors/include-media';

.profile-area {
  padding-top: 50px;

  h1 {
    margin-bottom: 50px;
  }

  .label {
    display: block;
    color: #000;
    margin: 0 0 10px;
  }

  .info-block,
  .topics-block {
    max-width: 810px;
    margin: 0 auto 50px;
  }

  .info-block {

    h2 {
      margin-bottom: 40px;
    }

    .form-row {


      @include media('>=tablet') {
        display: flex;
        justify-content: space-between;
        padding: 0 0 20px;
      }

      input {
        width: 100%;

        @include media('>=tablet') {
        }
      }
    }

    label {
      display: block;
      padding: 0 0 20px;

      @include media('>=tablet') {
        width: 350px;
        padding: 0;
      }
    }
  }

  .topics-block {
    p {
      margin-bottom: 40px;
    }
  }
}
