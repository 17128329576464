@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.risk-section {
  padding: 20px 0 45px;
  .row {
    padding-top: 35px;
    display: flex;

    @include media('>tablet') {
      justify-content: space-between;
    }

    @include media('<=tablet') {
      flex-direction: column;
    }
  }

  .col-text {
    text-align: center;
    display: flex;
    padding: 30px;

    @include media('>tablet') {
      text-align: left;
      align-items: flex-start;
      width: 50%;
    }

    @include media('<=tablet') {
      margin-bottom: 2rem;
      width: 100%;
    }

    img {
      display: inline-flex;
      vertical-align: top;
      width: 141px;
      min-width: 141px;
      margin: 0 30px 30px 0;

      @include media('>=tablet') {
        flex-shrink: 1;
      }
    }

    .info {
      text-align: left;
      @include media('>=tablet') {
        flex-grow: 1;
      }
    }

    @include media('>=tablet') {
      margin: 0 30px 0 0;
    }
  }

  .col-actions {

    @include media('>tablet') {
      width: 50%;
      padding: 0 0 0 60px;
    }

    @include media('<=tablet') {
      width: 100%;
      padding: 0 30px;
    }
  }

  h3 {
    font-weight: bold;
  }

  .popup-opener {
    min-width: 0;
    min-height: 0;
    padding: 0;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    font-size: 18px;
    margin-left: 5px;
  }

  .col-actions {
    ul {
      @extend %listreset;
    }

    li {
      position: relative;
      padding: 5px 0 5px 35px;
      line-height: 1.42857em;
    }

    .ico {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-Icon_DSSI {
        margin-left: 5px;
      }
    }
  }
}
