@import '../../assets/scss/base/variables';
@import '../../assets/scss/vendors/include-media';

.result-area {
  padding: 30px 0;

  .title-row {
    display: flex;
    color: $red;
  }
  .row {
    margin: 0 -15px 23px;
    border-bottom: 1px solid $form-element-border-color;
    display: flex;
  }

  .col {
    flex-grow: 1;
    width: 50%;
    padding: 0 15px;
  }

  .h5 {

    @include media('<desktop') {
      font-size: 16px;
    }

    @include media('<375px') {
      font-size: 13px;
      font-weight: 700;
      padding-top: 15px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 700;

    @include media('<tablet') {
      font-size: 15px;
    }
  }

  .txt-lbl {
    background: $mercury;
    display: inline-block;
    vertical-align: middle;
    font-size: 26px;
    font-weight: 700;
    padding: 15px 25px;
    margin-right: 10px;
    min-width: 150px;
    text-align: center;

    @include media('<tablet') {
      padding: 7px 8px;
      font-size: 24px;
    }
  }

  .correct {
    display: inline-block;
    vertical-align: bottom;
    font-size: 45px;
    margin-bottom: -9px;
    .icon-ico-check {
      &:before {
        color: $green;
      }
    }
  }

  .incorrect {
    display: inline-block;
    vertical-align: bottom;
    font-size: 39px;
    margin-bottom: -12px;
  }

  .info {
    padding: 10px 0 34px;
    @include media('<tablet') {
      padding-bottom: 40px;
    }
  }
}

.link {
  font-size: 26px;
}
