@import '../../assets/scss/base/variables';
@import '../../assets/scss/vendors/include-media';

.hide-header {
  .header {
    display: none;
  }
}

.header {
  background: $black;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .actions {
    display: flex;

    a {
      color: $white;
      margin-left: 15px;

      &:hover {
        opacity: .7;
      }
    }
  }

  .logo img {
    height: 40px;

    @include media('<tablet') {
      height: 30px;
    }
    @include media('<375px') {
      height: 22px;
    }
  }
}
