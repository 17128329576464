@import '../../../assets/scss/vendors/include-media';
@import '../../../assets/scss/base/variables';
@import '../../../assets/scss/base/helpers';

.intro-questions {
  background: $white;
  text-align: left;
  color: $black;
  padding: 25px;

  @include media('>=tablet') {
    padding: 80px 80px 15px;
  }

  .question.disabled {
    opacity: 0;
  }

  .actions {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 200px;
    padding: 16px 0;
    margin: 0 auto;

    @include media('>=tablet') {
      max-width: 182px;
    }

    @include media('>=tablet') {
      max-width: 225px;
    }

    button {
      width: 100%;
      border-radius: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 1;
      border: none;
      padding: 22px 10px;
      margin: 0 0 15px;
      box-shadow: inset 0 0 0 5px $red;
      background: $white;
      color: red;

      @include media('>=desktop') {
        margin: 0 0 23px;
      }

      &.active,
      &:hover {
        background: $red;
        color: $white;
      }
    }
  }

  .h3 {
    position: relative;
    padding-left: 45px;
    line-height: 1.42875em;
    
    @include media('>=tablet') {
      min-height: 72px;
    }

    @include media('>=desktop') {
      min-height: 84px;
    }

    span.color-red {
      position: absolute;
      top: -1px;
      left: 0;
    }
  }
}
