@import '../../assets/scss/vendors/include-media';
@import '../../assets/scss/base/variables';
@import '../../assets/scss/base/helpers';

.assessment-result {
  .row {
    margin: 0 -15px 23px;
    display: flex;
  }

  .col {
    flex-grow: 1;
    width: 50%;
    padding: 0 15px;
  }
}
