// forms
form,
fieldset {
	margin: 0;
	padding: 0;
	border-style: none;
}

form {
	.field {
		margin-bottom: 1rem;
	}
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
//input[type='url'],
input[type='date'],
textarea {
	-webkit-appearance: none;
	border-radius: 3px;
	box-sizing: border-box;
	border: 1px solid $form-element-border-color;
	padding: $form-element-padding;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;

	&:focus {
		border-color: $form-element-focus-border-color;
	}

	@include placeholder {
		color: $placeholder-color;
	}
}

select {
	-webkit-border-radius: 0;
}

textarea {
	resize: vertical;
	vertical-align: top;
}

a.button,
button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: inset 0 0 0 2px $red;
	background: $btn-background;
	color: $btn-color;
	font-size: 16px;
	line-height: 19px;
	font-weight: 900;
	padding: $btn-padding;
	min-width: 200px;
	min-height: 60px;
	border-radius: 40px;
	border: none;

	&:hover {
		background: $darken-red;
		box-shadow: inset 0 0 0 2px $darken-red;
	}

	&.btn-secondary {
		min-width: 155px;
		min-height: 50px;
		background: $white;
		color: $red;

		&:hover {
			box-shadow: inset 0 0 0 2px $darken-red;
			color: $darken-red;
		}

		&:disabled {
			opacity: 0.5;
		}
	}

	&.btn-white {
		box-shadow: inset 0 0 0 2px $white;
		color: $white;
		background: transparent;

		&:hover {
			box-shadow: inset 0 0 0 2px $gray;
			color: $gray;
		}
	}
}

button {
	&:focus-visible {
		outline: none;
	}
}
.chk-label {
	position: relative;
	overflow: hidden;
	display: inline-flex;
	vertical-align: middle;
	padding-left: 36px;
	font-size: 16px;
	line-height: 1.2;
	margin: 0 0 39px;

	span {
		display: inline-flex;
		vertical-align: middle;
		position: relative;

		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: -36px;
			width: 22px;
			height: 22px;
			background: $red;
			border-radius: 3px;
		}

		&:after {
			position: absolute;
			content: "\e901";
			font-family: 'icomoon' !important;
			speak: never;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			font-size: 12px;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			color: $white;
			top: 5px;
			left: -31px;
			display: none;
		}
	}

	input[type='checkbox'] {
		position: absolute;
		left: -100px;

		&:checked {
			& + span {
				&:after {
					display: block;
				}
			}
		}
	}
}
