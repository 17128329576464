@import '../../assets/scss/base/variables';

.banner {
  background: $gray;
  position: relative;
  z-index: 1;
  color: $white;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(55, 56, 61, 0.3);
    z-index: -1;
  }

  .bg-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
  }
}

.hero-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  height: 277px;

  .text-holder {
    position: relative;
    z-index: 1;
  }
  h1 {
    margin: 0;
  }
}