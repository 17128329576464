@import '../../../assets/scss/vendors/include-media';
@import '../../../assets/scss/base/variables';
@import '../../../assets/scss/base/helpers';

.questionnaire-intro {
  overflow: hidden;
  background-image: url("../../../assets/images/hero-img-07.jpg");
  background-size: cover;
  background-position: 50% 0;
  text-align: center;
  color: $white;
  padding: 35px 0 0;
  min-height: 100vh;

  .container {
    max-width: 806px;
    transition: transform .4s ease;
  }

  .active {
    background: red;
  }

  .nav {
    position: fixed;
    background: #000;
    width: 334px;
    bottom: 0;
    color: $white;
    padding: 23px 100px 5px 40px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);

    @include media('<420px') {
      width: 320px;
    }

    @include media('>=desktop') {
      bottom: 40px;
      left: auto;
      right: 50%;
      transform: translateX(512px);
    }

    @include media('>=1100px') {
      transform: translateX(506px);
    }

    .bar {
      position: relative;
      width: 156px;
      height: 10px;
      background: rgba(255, 255, 255, 0.38);
      border-radius: 4px;
      margin: 0 0 5px;
      @include media('<420px') {
        width: 156px;
      }
    }

    .filler {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      background: $mercury;
      border-radius: 4px;
    }

    .btn-prev:disabled,
    .btn-next:disabled {
      .icon-ico-arrow-down:before,
      .icon-ico-arrow-up:before {
        color: rgba(255, 255, 255, 0.38);
      }
    }

    .btn-prev,
    .btn-next {
      position: absolute;
      width: 57px;
      height: 57px;
      min-width: 0;
      min-height: 0;
      padding: 0;
      border-radius: 0;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      top: 0;
      right: 57px;
      background: #000;
      box-shadow: inset 0 0 0 5px #000;

      .icon-ico-arrow-down:before,
      .icon-ico-arrow-up:before {
        //color: rgba(255, 255, 255, 0.38);
        color: $white;
      }

      i {
        display: none;
      }

      &:hover {
        background: $white;

        [class^="icon-"], [class*=" icon-"] {
          &:before {
            color: #000;
          }
        }
      }

      &:disabled {
        .icon-ico-arrow-down:before,
        .icon-ico-arrow-up:before {
          color: rgba(255, 255, 255, 0.38);
        }
      }
    }

    .btn-next {
      right: 0;
    }
  }

  .progress {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
  }

  .bar {
    position: relative;
    width: 156px;
    height: 10px;
    background: rgba(255, 255, 255, 0.38);
    border-radius: 4px;
    margin: 0 0 5px;
  }

  h1 {
    font-weight: 400;
    font-size: 48px;
    line-height: (60/48);
    margin: 0 0 20px;
  }

  p {
    font-size: 24px;
    line-height: (29/24);
    margin: 0 0 45px;
  }

  button:disabled {
    pointer-events: none;
  }

  .questions-footer {
    p {
      margin: 0 0 23px;
    }

    button:disabled {
      opacity: .4;
    }
  }
}

.questions-footer {
  position: relative;
  background: $white;
  font-size: 14px;
  padding: 25px 25px 50px;
  color: $black;

  @include media('>=desktop') {
    font-size: 24px;
    line-height: (29/24);
    padding: 35px 105px 84px;
  }

  &.hidden .content {
    opacity: 0;
  }

  &:not(.hidden) {
    &:before {
      position: absolute;
      content: '';
      left: 25px;
      right: 25px;
      top: 0;
      height: 2px;
      background: $gray;

      @include media('>=desktop') {
        left: 80px;
        right: 80px;
      }
    }
  }
}
